.banner {
  background: url(../../assets/img/new/construct.png);
  background-size: cover;
  background-repeat: no-repeat;
}
inform {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #18739d;
  /* margin-left: 42px; */
  /* height: 335px;
    width: 239px; */
  border-radius: 4px;
  /* height: 161px; */
  height: 100%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;
}
.one {
  position: relative;
}
.one:hover inform {
  opacity: 1;
}
.two {
  position: relative;
}
.two:hover inform {
  opacity: 1;
}
.three {
  position: relative;
}
.three:hover inform {
  opacity: 1;
}
.four {
  position: relative;
}
.four:hover inform {
  opacity: 1;
}
.five {
  position: relative;
}
.five:hover inform {
  opacity: 1;
}
.six {
  position: relative;
}
.six:hover inform {
  opacity: 1;
}
.seven {
  position: relative;
}
.seven:hover inform {
  opacity: 1;
}
.eight {
  position: relative;
}
.eight:hover inform {
  opacity: 1;
}
.nine {
  position: relative;
}
.nine:hover inform {
  opacity: 1;
}
@media screen and (max-width: 600px) {
  inform {
    width: 343px;
  }
}
