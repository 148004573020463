.image {
  object-fit: fill;
  /* padding: 10px; */
}

.slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.slick-dots li {
  margin: 0 0.25rem;
}
.slick-dots button {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: orange;
  text-indent: -9999px;
}
.slick-dots li button {
  background-color: grey !important;
  width: 12px;
  height: 12px;
}

.slick-dots li.slick-active button {
  background-color: #1976d2 !important;
}

@media screen and (max-width: 600px) {
  .image {
    /* height: 329px; */
  }
}
@media (min-width: 601px) and (max-width: 820px) {
  .image {
    /* height: 400px; */
  }
}
