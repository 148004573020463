@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

.drop {
  /* background: url(../../public/hands.png); */
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.7)
    ),
    url(../../public/hands.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* .markdown {
  background-color: transparent !important;
} */
/* .markdown p {
  background-color: transparent !important;
  font-size: 16px !important;
} */

.markdown * {
  background-color: transparent !important;
   font-size: 18px !important; 
  font-family:'PT Sans', sans-serif !important;
  font-weight: 200 !important;
  letter-spacing: 1px;
   color: #ffff !important; 
}
.markdown p span strong {
  font-weight: 500 !important;
}
/* .markdown p  span  {
  font-weight: 500 !important;
  font-size: 16px !important; 
} */
.markdown p:empty {
  height: 10px !important;
}
.sliderMarkdown * {
  font-size: 16px !important;
}






/* blog */


.markdownBlog * {
  /* text-align: justify; */
}

.markdownBlog p:empty {
  height: 30px !important;
  /* text-align: justify !important; */
}
.markdownBlog p strong {
  font-weight: 600 !important;
  font-size: 22px;
  /* height: 30px !important; */
  /* text-align: justify !important; */
}
.markdownBlog p span {
  font-size: 18px !important;
}

.markdownBlog p {
  text-align: justify;
}
.markdownBlog h2 * {
  font-weight: 600;
  font-size: 28px;
  text-align: left !important;
}
.markdownBlog h3 * {
  font-weight: 500;
  font-size: 25px;
}
.markdownBlog h4 * {
  font-weight: 500;
  font-size: 20px;
}



@media (max-width: 767px) {

  .markdownBlog * {
    /* text-align: left; */
  }
  .markdownBlog h2 * {
    font-weight: 600;
    font-size: 28px;
    text-align: left !important;
  }












  /* styles for mobile devices */
  .card-body {
    padding: 10px !important;
  }
  .collapse-content {
    padding: 4px !important;
  }
  .markdown * {
    /* background-color: transparent !important;
    font-size: 18px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 200 !important;
    text-align: justify;
    letter-spacing: 1px;
    color: #ffff !important; */
  }
  /* other styles */
}

/* hexgon */
/* .hex {
  margin-top: 30px;
  width: 104px;
  height: 60px;
  background-color: red;
  border-color: red;
  position: relative;
  display: inline-block;
}
.hex:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
  position: absolute;
  top: -30px;
}
.hex:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -30px;
  border-top: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
} */

/* hexagon  */

.honeycomb {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  transform: translateY(80px);
}

.honeycomb-cell {
  -webkit-box-flex: 0;
  flex: 0 1 250px;
  max-width: 250px;
  height: 137.5px;
  margin: 65.4px 12.5px 25px;
  position: relative;
  padding: 0.5em;
  text-align: center;
  z-index: 1;
  box-shadow: 0px 0px 15px 0 rgba(0, 0, 0, 0.1);
}

.honeycomb-cell_img {
  object-fit: cover;
  object-position: center;
  filter: grayscale(100%);
}

.honeycomb-cell_title {
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 1em;
  transition: opacity 350ms;
}

.honeycomb-cell_title > small {
  font-weight: 300;
  margin-top: 0.25em;
}

.honeycomb-cell::before,
.honeycomb-cell::after {
  content: "";
}

.honeycomb-cell::before,
.honeycomb-cell::after,
.honeycomb-cell_img {
  top: -50%;
  left: 0;
  width: 100%;
  height: 200%;
  display: block;
  position: absolute;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: -1;
}

.honeycomb-cell::before {
  background: #fff;
  transform: scale(1.055);
}

.honeycomb-cell::after {
  background: #111111;
  opacity: 0.7;
  transition: opacity 350ms;
  -webkit-transition: opacity 350ms;
}

.honeycomb-cell:hover .honeycomb-cell_title {
  opacity: 0;
}

.honeycomb-cell:hover .honeycomb-cell_img {
  filter: grayscale(0%);
}

.honeycomb-cell:hover::before {
  background: #fc5130;
}

.honeycomb-cell:hover::after {
  opacity: 0;
}

.honeycomb_Hidden {
  display: none;
  opacity: 0;
  width: 250px;
  margin: 0 12.5px;
}

/****** Responsive *******/

@media (max-width: 550px) {
  .honeycomb-cell {
    margin: 81.25px 25px;
  }
}

@media (min-width: 550px) and (max-width: 825px) {
  .honeycomb-cell:nth-child(3n) {
    margin-right: calc(50% - 125px);
    margin-left: calc(50% - 125px);
  }

  .honeycomb_Hidden:nth-child(3n + 5) {
    display: block;
  }
}

@media (min-width: 825px) and (max-width: 1100px) {
  .honeycomb-cell:nth-child(5n + 4) {
    margin-left: calc(50% - 275px);
  }

  .honeycomb-cell:nth-child(5n + 5) {
    margin-right: calc(50% - 275px);
  }

  .honeycomb_Hidden:nth-child(5n),
  .honeycomb_Hidden:nth-child(5n + 3) {
    display: block;
  }
}

@media (min-width: 1100px) {
  .honeycomb-cell:nth-child(7n + 5) {
    margin-left: calc(50% - 400px);
  }

  .honeycomb-cell:nth-child(7n + 7),
  .honeycomb-cell:nth-child(7n + 5):nth-last-child(2) {
    margin-right: calc(50% - 400px);
  }

  .honeycomb_Hidden:nth-child(7n + 7),
  .honeycomb_Hidden:nth-child(7n + 9),
  .honeycomb_Hidden:nth-child(7n + 11) {
    display: block;
  }
}

/* glowing pro  */

.box {
  position: relative;
  height: 400px;
  width: 380px;
  display: block;
  background: linear-gradient(0deg, black, #444);
}

.glowing::before {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  background: linear-gradient(
    45deg,
    #e8f74d,
    #f60 d9,
    #0f6,
    #13ff13,
    #ad27ad,
    #bd2681,
    #6512b9,
    #f30 de,
    #5aabde
  );
  background-size: 400%;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: -1;
  animation: glower 20s linear infinite;
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}
