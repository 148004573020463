@tailwind base;
@tailwind components;
@tailwind utilities;

.collapse-plus .collapse-title::after {
  top: 40px;
  right: 40px;
  font-size: 2rem;
}
*{
  font-family: 'PT Sans', sans-serif;
}
html,
body {
  overflow-x: hidden;
  background: linear-gradient(
    180deg,
    #151136 26.56%,
    #282559 39.58%,
    #161237 55.21%,
    #282457 70.83%,
    #0a0c22 95.83%,
    #212458 99.48%
  );
  color: white;
}

/*----- control btn -------*/
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;

  line-height: 60px;

  transform: 0.5s;
  z-index: 5;
}
.control-btn .prev {
  left: -20px;
}
.control-btn .next {
  right: -20px;
}
.control-btn .prev i,
.control-btn .next i {
  font-size: 20px;
  transition: 0.5s;
  color: #fff;
}
.control-btn .prev:hover,
.control-btn .next:hover {
  /* background: #e94560; */
  color: white;
  cursor: pointer;
}
/*----- control btn -------*/

.progress-bar {
  position: fixed;
  top: 20;
  left: 0;
  right: 0;
  height: 10px;
  background: red;
  transform-origin: 0%;
}
