.banner1 {
  background: url(../../../public/Group\ 8.png);
  background-repeat: no-repeat;
  background-size: cover;
}
info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  background-color: #18739d;
  border-radius: 4px;
  padding-left: 20px;
  height: 100%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;
}
.hrms {
  position: relative;
}
.hrms:hover info {
  opacity: 1;
}
/* .two{
    position: relative;
  }
  .two:hover info{
    opacity: 1;
  }
  .three{
    position: relative;
  }
  .three:hover info{
    opacity: 1;
  }
  .four{
    position: relative;
  }
  .four:hover info{
    opacity: 1;
  }
  .five{
    position: relative;
  }
  .five:hover info{
    opacity: 1;
  }
  .six{
    position: relative;
  }
  .six:hover info{
    opacity: 1;
  }
  .seven{
    position: relative;
  }
  .seven:hover info{
    opacity: 1;
  }
  .eight{
    position: relative;
  }
  .eight:hover info{
    opacity: 1;
  }
  .nine{
    position: relative;
  }
  .nine:hover info{
    opacity: 1;
  } */
